::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}


.registrationModal {
  height: 75vh;
  margin-top: 10px;
  overflow-y: scroll;
  padding: 32px;
}


.registrationModal::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
.registrationModal::-webkit-scrollbar-thumb {
  background: #fefefe;
}